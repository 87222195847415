import {ChangeDetectionStrategy, Component} from '@angular/core'
import {Monologic} from '../../dialog/monologic.interface'
import {CommonModule} from '@angular/common'
import {SupportPortraitComponent} from '../support-portrait/support-portrait.component'
import {faCopy} from '@fortawesome/pro-solid-svg-icons/faCopy'
import {faEnvelope} from '@fortawesome/pro-solid-svg-icons/faEnvelope'
import {faFileCheck} from '@fortawesome/pro-solid-svg-icons/faFileCheck'
import {faPhoneAlt} from '@fortawesome/pro-solid-svg-icons/faPhoneAlt'
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome'
import {PipesModule} from '../../../shared/pipes/pipes.module'
import {ClipboardModule} from '@angular/cdk/clipboard'
import {BehaviorSubject, timer} from 'rxjs'
import {CardsModule} from '../../cards/cards.module'
import {SupportPerson} from '../support.types'
import {I18nDirective} from '../../i18n/i18n.directive'
import {DialogModule} from '../../dialog/dialog.module'

@Component({
  selector: 'cft-support-contact-dialog',
  templateUrl: './support-contact-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    SupportPortraitComponent,
    FontAwesomeModule,
    PipesModule,
    ClipboardModule,
    CardsModule,
    I18nDirective,
    DialogModule,
  ],
})
export class SupportContactDialogComponent implements Monologic<Props> {
  props!: Props

  readonly copyIcon = faCopy
  readonly copiedIcon = faFileCheck
  readonly phoneIcon = faPhoneAlt
  readonly emailIcon = faEnvelope
  readonly phoneIconDef = {collection: this.phoneIcon.prefix, name: this.phoneIcon.iconName}
  readonly emailIconDef = {collection: this.emailIcon.prefix, name: this.emailIcon.iconName}

  phoneCopied$ = new BehaviorSubject(false)
  emailCopied$ = new BehaviorSubject(false)

  setData(data: Props): void {
    this.props = data
  }

  itemCopied(copied: boolean, subject: BehaviorSubject<boolean>) {
    if (!copied) return
    subject.next(true)
    timer(2000).subscribe(() => subject.next(false))
  }
}

export interface Props {
  contact: SupportPerson
}
