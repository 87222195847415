import {Injectable} from '@angular/core'
import {map, Observable, zip} from 'rxjs'
import {TranslocoService} from '@ngneat/transloco'
import {DialogService} from '../../craft/dialog/dialog.service'
import {SupportContactDialogComponent} from '../../craft/support/support-contact-dialog/support-contact-dialog.component'
import {SupportPerson} from '../../craft/support/support.types'

@Injectable({
  providedIn: 'root',
})
export class SupportService {
  constructor(
    private readonly translationService: TranslocoService,
    private readonly dialogService: DialogService,
  ) {}

  responsiblePerson$(): Observable<SupportPerson> {
    return zip([
      this.translationService.selectTranslate('shared.support.responsible.name'),
      this.translationService.selectTranslate('shared.support.responsible.portrait'),
      this.translationService.selectTranslate('shared.support.responsible.role'),
      this.translationService.selectTranslate('shared.support.phone'),
      this.translationService.selectTranslate('shared.support.email'),
    ]).pipe(
      map(([name, portrait, role, phone, email]) => {
        return {
          name,
          portraitUrl: `/assets/images/team/${portrait}`,
          role,
          phone,
          email,
        }
      }),
    )
  }

  showContactSupportDialog(contact: SupportPerson) {
    return this.dialogService.show(SupportContactDialogComponent, {contact})
  }
}
